import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/7528353467c674d1b6136b8298e6ee82ffbc543a60a8a67b0e15245433a5fc7f/navBarModifier';
import SharedProviders_1_0 from './extensions/70106ff113ba414ea795b1d2e9d5d695f84d42246436698c0b0f479658d072c0/providers';
import SharedProviders_2_0 from './extensions/software-houses/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
